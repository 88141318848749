import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { differenceInDays, formatDistance, format } from "date-fns";
import { Link } from "gatsby";

function BlogPostPreview(props) {
  return (
    <Link
      to={`/${props.slug.current}/`}
      className="group  flex h-full items-stretch font-normal text-typography-body no-underline shadow-md  hover:text-typography-body"
    >
      <div className="">
        {" "}
        {props.featuredImage && props.featuredImage.asset && (
          <div className="overflow-hidden ">
            <GatsbyImage
              image={props.featuredImage.asset.gatsbyImageData}
              loading="lazy"
              className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
            />
          </div>
        )}
        <div className="px-6 py-8"><div className="grid gap-y-4">        {props.publishedAt && (
            <div className="text-sm font-normal text-gray-700">
              <p className="mb-0 uppercase tracking-[.03rem] leading-[18px] text-[14px] text-gray-400">
                {differenceInDays(new Date(props.publishedAt), new Date()) > 3
                  ? formatDistance(new Date(props.publishedAt), new Date())
                  : format(new Date(props.publishedAt), "MMMM d, yyyy")}{" "}
              </p>
            </div>
          )}
          <h3 className="heading-six">{props.title}</h3>
          <p>{props.categories.title}</p></div>
  
          <span className="border-b-2 border-b-primary-500/80 text-primary-500/80 group-hover:border-b-primary-500 group-hover:text-primary-500">
            {" "}
            read more{" "}
          </span>
        </div>
      </div>
    </Link>
  );
}

export default BlogPostPreview;
