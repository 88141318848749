import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogPostGrid from "../components/Blog/BlogPostGrid";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-global.jpg" }
    ) {
      publicURL
    }
    blogs: allSanityBlogs(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const postNodes =
    data &&
    data.blogs &&
    mapEdgesToNodes(data.blogs)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Home Loan Blog | Mortgage Brokers | Nikkael"
        description="Get expert advice and homebuying tips—it's all right here on Nikkael's Home Loan Blog. You'll learn everything you need to know to make smart choices."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden py-20 md:py-28">
        <div className="absolute left-0 top-0 h-full w-full ">
          <StaticImage
            src="../images/3.0 Mortgage Rate Options/Hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative max-w-[640px]">
            <h1 className="mb-0 to-typography-heading">Nikkael’s Home Loan Blog</h1>
            <p className="heading-six font-normal text-gray-600">Get expert advice and homebuying tips—it’s all right here!</p>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-16 md:mb-32 md:pt-20">
        <div className="container">
          {postNodes && postNodes.length > 0 && (
            <BlogPostGrid nodes={postNodes} />
          )}
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Page;
